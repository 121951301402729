<template>
  <router-view 
    v-slot="{ Component }"
    :active="active"
    :ready="ready"
    :offset="view.offset"
    :web="web"
    :device="device"
    :version="config.version"
    :dev="dev"
    :class="{ 'bg-black': dark }"
  >
    <v-fade-transition>
      <component :is="Component" />
    </v-fade-transition>
  </router-view>
  <p 
    v-if="env=='staging'"
    class="version text-caption text-right pa-2"
  >
    v{{ version }}
  </p>
</template>

<style>
  .version {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100000;
    text-shadow: 1px 1px white;
  }
</style>

<script setup>
  import { Capacitor } from '@capacitor/core'
  import { App } from '@capacitor/app';
  import { Device as capDevice } from '@capacitor/device';
  import { storage, getMeta, getAppConfig, versionStatus } from '@/services/global'
  import { get as getUser, device as myDevice } from '@/api/user'
  import i18n from '@/plugins/i18n';
  import { sync } from 'vuex-pathify'
  import { SplashScreen } from '@capacitor/splash-screen';
  import { StatusBar } from '@capacitor/status-bar';
  import { SafeArea } from 'capacitor-plugin-safe-area';
  import * as Sentry from "@sentry/capacitor";
  import * as SentryVue from "@sentry/vue";
</script>
<script>
  export default {
    name: 'App',

    data: () => ({
      refreshing: false,
      registration: null,
      updateExists: false,
      view: {
        offset: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      active: true,
      ready: false,
      device: null,
      locale: null,
      dev: import.meta.env.VITE_APP_MODE!='production',
      env: import.meta.env.VITE_APP_MODE,
      version: __APP_VERSION__,
      web: !Capacitor.isNativePlatform(),
    }),

    computed: {
      user: sync('user/data', false),
      config: sync('app/config', false),
      
      dark () {
        const route = this.$route;
        return _.startsWith(route.path, '/signup')||_.startsWith(route.path, '/hello');
      }
    },

    watch: {
      $route: {
        immediate: true,
        handler (route, from) {
          if (['signup', 'hello'].includes(from?.name)) {
            // update app/device info
            storage.get('auth').then((auth) => {
              if (!!auth) this.setup();
            })
          }
          if (_.has(route.query, 'invite')) {
            storage.set('inviteToken', route.query.invite, 'session');
          }
          if (_.has(route.query, 'debug')) {
            storage.set('debug', true);
            this.dev = true;
          }else if (!this.dev) {
            storage.get('debug').then(value => {
              this.dev = value===true;
            });
          }
        },
      },
    },

    methods: {
      
      async init () {
        if (!this.web) {
          StatusBar.setOverlaysWebView({ overlay: true });

          SafeArea.getSafeAreaInsets().then(({ insets }) => {
            this.setSafeArea(insets);
          });
          SafeArea.addListener('safeAreaChanged', ({ insets }) => {
            this.setSafeArea(insets);
          });

          App.addListener('appUrlOpen', (event) => {
            const path = event.url.split('.club').pop();
            console.log('deep link:', JSON.stringify(event), path);
            if (!!path) {
              this.$router.push(path);
            }
          });
        }

        const auth = await storage.get('auth');
        if (!!auth?.token) {
          getUser(
            this,
            auth.token,
            (user, surveys) => {
              console.log(user, surveys);
              this.user = user;
              this.$root.surveys = surveys;
              storage.set('user', user);
              this.$nextTick(() => {
                this.onReady();
              })
            },
            (error) => {
              // TODO
            },
          )
        }else{
          this.onReady();
        }

        this.setup();
      },

      onReady () {
        this.ready = true;
        setTimeout(() => {
          SplashScreen.hide();
        }, 500);
      },

      onStateChange ({ isActive }) {
        this.active = isActive;
      },

      showRefreshUI () {
        this.toggleToast(
          true,
          'Nova versão do app disponível!',
          0,
          {
            toggle: true,
            text: 'Atualizar',
            action: this.refreshApp
          },
        )
      },

      setup () {
        this.getLang();
        getAppConfig(import.meta.env.VITE_APP_CONFIG).then((config) => {
          config.version.status = versionStatus(this.version, config?.version);
          this.config = config;
          console.log('config', config);
        }).catch((error) => {
          console.error('config error', error);
        }).finally(() =>{
          this.$nextTick(() => {
            this.updateDevice(this.device, this.config);
          })
        });
      },

      async getLang () {
        let locale = Intl.DateTimeFormat().resolvedOptions().locale;
        // let locale = (await capDevice.getLanguageCode()).value;
        this.locale = locale;
        let lang = locale.indexOf('-')>=0 ? _.first(_.split(locale, '-')) : locale;
        lang = _.indexOf(i18n.supported, lang)>=0 ? lang : _.first(i18n.supported);
        this.$vuetify.locale.current = lang;
        document.documentElement.setAttribute('lang', lang);
        const mLocale = _.find(this.$moment.locales(), l => l.includes(lang));
        this.$moment.locale(mLocale);
        this.$moment.updateLocale(mLocale, i18n[lang].datetime);
      },
      
      async getDeviceInfo () {
        this.device = await capDevice.getInfo();
        this.device['uuid'] = (await capDevice.getId()).identifier;
      },

      async updateDevice (info=null, config={}) {
        if (!info) {
          info = await capDevice.getInfo();
          this.device = info;
        }
        config = { running: this.version, ...config.version };
        const data = _.mapValues({
          appInfo: { ...config, language: this.$vuetify.locale.current, timestamp: Date.now() },
          deviceInfo: { ..._.pick(info, ['model', 'platform', 'osVersion']), locale: this.locale }
        }, o => _.join(_.reduce(o, (d, value, key) => {
          d.push(`${key}:${value}`);
          return d;
        }, []), '|'));

        const auth = await storage.get('auth');
        if (!!auth?.token) {
          const last = await myDevice(this, auth.token);
          if (!_.isEqual(last, data)) {
            myDevice(this, auth.token, data);
          }
        }
      },

      setSafeArea (insets) {
        for (const [key, value] of Object.entries(insets)) {
          // console.log('SafeArea', key, value);
          this.view.offset[key] = value;
          document.documentElement.style.setProperty(
            `--safe-area--${key}`,
            `${value}px`,
          );
        }
      },

      initSentry () {
        Sentry.init({
          environment: import.meta.env.VITE_APP_MODE,
          dsn: "https://9f89f790895945c9c98a5e67168d7978@o4507097509789696.ingest.us.sentry.io/4507097577422848",
          release: 'table-4@'+this.version,
          integrations: [
            SentryVue.browserTracingIntegration(),
            SentryVue.captureConsoleIntegration({ levels: ['error'], }),
            SentryVue.extraErrorDataIntegration({ depth: 3 }),
            SentryVue.replayIntegration(),
          ],
          // Performance Monitoring
          tracesSampleRate: 1.0, //  Capture 100% of the transactions
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ['localhost', /^https:\/\/app\.table4\.club/, /^https:\/\/stg\.app\.table4\.club/],
          // Session Replay
          replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
          replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        }, SentryVue.init);
      }
    },

    created () {
      this.$router.afterEach((route) => {
        const { title } = getMeta(this);
        document.title = title;
      });
      this.getDeviceInfo();
    },

    mounted () {
      this.init();
      if (import.meta.env.VITE_APP_MODE=='production') this.initSentry();
      console.log('v'+this.version, import.meta.env.VITE_APP_MODE);

      this.$gtag.customMap({ 'dimension1': 'appVersion' });
      this.$gtag.set({
        'appVersion': this.version
      });

      App.addListener('appStateChange', this.onStateChange);

    }
  }

  // ENABLE_USER_SCRIPT_SANDBOXING = NO
</script>
