// Styles
import '@mdi/font/css/materialdesignicons.css'
import '@/styles/main.scss'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Translations provided by Vuetify
import { en, pt, es } from 'vuetify/locale'

// Table 4 i18n
import i18n from './i18n'

i18n.test(i18n.supported, i18n.supported.reduce((languages, l) => {
  languages[l] = i18n[l]
  return languages;
}, {}))

const base = { en, pt, es };
let messages = {}
for (const l in base) {
  messages[l] = {
    ...base[l],
    ...i18n[l]
  }
}

// Vuetify
import { createVuetify } from 'vuetify'

const black = '#1F2120';
const background = '#F3EDE6';

const light = {
  dark: false,
  colors: {
    'on-background': black,
    'on-surface': black,
    background,
    surface: '#FFF',
    
    primary: '#EB5940', // cinnabar
    secondary: '#FFB28C', // peach
    black,
    'on-black': background,
    lime: '#DEF080',
    lavender: '#B0A8FE',
    'on-lavender': black,
    bone: '#EAE2D5',
    'on-bone': black,

    success: '#86DAB1',
    warning: '#F8C96B',
    error: '#F48E6D',
  },
  variables: {
    'border-color': black,
    'theme-on-code': black,
    'high-emphasis-opacity': 0.8,
    'medium-emphasis-opacity': 0.64,
    'disabled-opacity': 0.4,
  }
}

export default createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'light',
    variations: {
      colors: ['primary', 'secondary'],
      lighten: 4,
      darken: 4,
    },
    themes: {
      light,
    },
  },
  locale: {
    locale: 'en',
    fallback: 'en',
    messages,
  },
  icons: {
    defaultSet: 'mdi'
  }
})

/*
    --black-lighten-2: #868698;
    --cinnabar-accent: #f56046;
    --black-lighten-1: #48485b;
    --black-accent: #181825;
    --white: white;
    --caviar-black: #1f2120;
    --black-lighten-3: #d8d8df;
    --bone: #eae2d5;
    --yellow-lighten-2: #fff199;
    --lavender-accent: #c6bfff;
    --rice: #f3ede6;
    --yellow-lighten-1: #ffee70;
    --lavender: #b0a8fe;
    --lemon-accent: #def080;
    --black-lighten-4: #f4f4f6;
    --yellow-lighten-3: #fff8cc;
    --yellow-lighten-4: #fffceb;
    --lavender-darken-1: #5d5791;
    --lavender-darken-2: #454078;
    --lavender-darken-3: #2e295f;
    --lavender-darken-4: #19124a;
    --lemon-lighten-1: #eaf99a;
    --lemon-lighten-2: #f6ffc0;
    --lemon-lighten-3: #fcffdd;
    --lemon-lighten-4: #fcffdd;
    --peach-accent: #ffb692;
    --peach: #ffb28c;
    --peach-lighten-1: #ffdbcb;
    --peach-lighten-2: #ffede6;
    --peach-lighten-3: #fff8f6;
    --peach-lighten-4: #fffbff;
    --cinnabar: #eb5940;
    --cinnabar-lighten-1: #ff8a74;
    --cinnabar-lighten-2: #ffb4a6;
    --cinnabar-lighten-3: #ffdad3;
    --cinnabar-lighten-4: #ffede9;
    --lemon: #dceb8d;
*/
