// directives/doubleTap.js

export default {
  mounted(el, binding) {
    let tapCount = 0;
    let tapTimeout;

    const resetTap = () => {
      tapCount = 0;
      clearTimeout(tapTimeout);
    };

    const onTap = (e) => {
      tapCount++;
      if (tapCount === 1) {
        tapTimeout = setTimeout(resetTap, 300); // Reset if no second tap within 300ms
      } else if (tapCount === 2) {
        binding.value(e);
        resetTap();
      }
    };

    el.__vueDoubleTap__ = onTap; // Store the handler to remove it later
    el.addEventListener('touchend', onTap);
  },
  unmounted(el) {
    el.removeEventListener('touchend', el.__vueDoubleTap__);
    delete el.__vueDoubleTap__;
  }
};
