import { createStore } from 'vuex'
// import type { GetterTree } from 'vuex'
import Pathify, { make } from 'vuex-pathify'
import VuexPersistence from 'vuex-persist';
// import localForage from 'localforage';

import * as modules from './modules'

Pathify.options.mapping = 'simple'

const vuex = new VuexPersistence({
  key: 'vuex',
  storage: window.localStorage, 
  // asyncStorage: true,
});

const vueStore =  createStore({
  plugins: [
    Pathify.plugin,
    vuex.plugin
  ],
  modules
})


export default vueStore