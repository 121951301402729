import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import $api from './plugins/axios'
import { translate } from './plugins/i18n'
import store from './store'
import moment from './plugins/moment';
import 'moment/dist/locale/pt-br';
import _ from 'lodash';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import VueGtag from "vue-gtag";
import { trackError } from './services/global'
import { registerSW } from 'virtual:pwa-register'
import doubleTap from './directives/doubleTap';

registerSW({ immediate: true })

defineCustomElements(window);

window._ = _;

loadFonts()

const app = createApp(App);

app.config.globalProperties.$api = $api;
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$translate = translate;

app.config.errorHandler = function (err, vm, info) {
  trackError(vm, err);
};

app
  .use(router)
  .use(vuetify)
  .use(store)
  .directive('double-tap', doubleTap)
  .use(VueGtag, {
    enabled: import.meta.env.PROD,
    config: {
      id: import.meta.env.VITE_GTAG,
    },
    pageTrackerTemplate (route) {
      return {
        page: route.path,
        title: window.document.title,
        location: window.location.href,
      }
    },
    debug: {
      enabled: !import.meta.env.PROD,
      // sendHitTask: toggle,
    },
  }, router)
  .mount('#app')
