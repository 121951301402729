/**
 * plugins/webfontloader.js
 *
 * webfontloader documentation: https://github.com/typekit/webfontloader
 */

export const families = [
  { name: 'Open+Sans', weights: '300,300italic,400,400italic,500,500italic,700,700italic' },
  { name: 'Poppins', weights: '400,400italic,500,500italic,600,600italic,700,700italic' },
  { name: 'Fraunces', weights: '400,400italic,500,500italic,700,700italic' },
  { name: 'Barlow+Semi+Condensed', weights: null },
];

export async function loadFonts () {
  const webFontLoader = await import(/* webpackChunkName: "webfontloader" */'webfontloader')

  webFontLoader.load({
    google: {
      families: families.map(family => family.name + (!!family.weights ? ':' + family.weights : ''))
    },
  })
}
