// Imports
import {
  make,
} from 'vuex-pathify'

const state = {
  data: {
    id: null,
    username: null,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    image: null,
    typeAuth: '',
    dateTerms: null,
    prefZones: null,
    inviteToken: null,
    emailValidation: null,
    isTemporary: true,
    isActive: true,
  },
  seats: 0,
  product: null,
  profile: {
    interests: null,
    gastronomy: null,
    personal: null
  },
  settings: {
    tables: [],
    availability: {
      meals: null,
      days: null
    },
    location: {
      selected: null,
      survey: null
    },
    reservation: null,
  },
  auth: {
    username: null,
    token: null,
    timestamp: null,
  },
};

const mutations = make.mutations(state)

const actions = {}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
